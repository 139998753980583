import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { setMetricsInputIds } from "../../common/dataRefining/store/dataRefiningSlice";
import { triggerRefreshMetrics } from "../store/analyticsSlice";

const useSourceFilter = () => {
  const dispatch = useAppDispatch();

  const { inputTypes } = useAppSelector((state) => state.documentTypesReducer);

  const { inputIds } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const inputTypesSelectOptions = useMemo(() => {
    return inputTypes.map((it) => ({
      label: it.displayName,
      value: it.id,
    }));
  }, [inputTypes]);

  const handleChange = useCallback(
    (value: Array<string>) => {
      dispatch(setMetricsInputIds(value));
      dispatch(triggerRefreshMetrics());
    },
    [dispatch]
  );

  return {
    inputIds,
    inputTypesSelectOptions,
    handleChange,
  };
};

export default useSourceFilter;
