import React, { FC } from "react";
import { Tooltip } from "antd";

type Props = {
  value: string;
};

const TooltipValue: FC<Props> = ({ value }) => {
  return (
    <Tooltip title={value} placement="topLeft">
      <div className={"value"}>{value}</div>
    </Tooltip>
  );
};

export default TooltipValue;
