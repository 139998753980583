import React, { FC, memo, useRef, useState } from "react";
import { InputNumber } from "antd";
import { GroupBlockEntityType } from "../../../";
import useAnnotations from "../../../../annotation";
import { useAppDispatch } from "../../../../app";
import {
  addBlurredEntity,
  changeEditing,
} from "../../../../app/store/appSlice";
import { isEmpty } from "../../../../common/utilities/value";

type StepInfo = {
  offset: any;
  type: "up" | "down";
};

type EditEntityNumberProps = {
  entityType: GroupBlockEntityType;
  documentId: string;
  annotationId: string;
  defaultValue: number;
  groupBlockIndex: number;
};

const EditEntityNumber: FC<EditEntityNumberProps> = ({
  entityType,
  documentId,
  annotationId,
  defaultValue,
  groupBlockIndex,
}: EditEntityNumberProps) => {
  const dispatch = useAppDispatch();

  const isCancelled = useRef(false);
  const [value, setValue] = useState<number | null>(defaultValue);

  const { updateAnnotation } = useAnnotations({
    documentId,
    groupBlockIndex,
  });

  const handleStep = (updateValue: number, info: StepInfo): void => {
    const { type } = info;

    switch (type) {
      case "up":
        setValue((oldValue: number | null) => {
          if (oldValue) {
            return oldValue + updateValue;
          }
          return oldValue;
        });
        break;
      case "down":
        setValue((oldValue: number | null) => {
          if (oldValue) {
            return oldValue - updateValue;
          }
          return oldValue;
        });
        break;
    }
  };

  const handleEnter = (event: any) => {
    event.stopPropagation();
    dispatch(changeEditing(null));
    if (
      value &&
      !isEmpty(value) &&
      !Number.isNaN(Number(value)) &&
      !isCancelled.current
    ) {
      updateAnnotation(annotationId, value.toString(), entityType, true, false);
    }
  };

  const onBlur = (event: any) => {
    handleEnter(event);
    dispatch(
      addBlurredEntity(`${entityType.entityType.id}-${groupBlockIndex}`)
    );
  };

  const handleCancel = (event: any) => {
    event.stopPropagation();
    isCancelled.current = true;
    dispatch(changeEditing(null));
  };

  return (
    <span className="edit-entity edit-entity-number">
      <InputNumber
        size={"small"}
        defaultValue={value ? value : undefined}
        decimalSeparator={","}
        value={value}
        onStep={handleStep}
        onChange={setValue}
        onPressEnter={handleEnter}
        onBlur={onBlur}
        onClick={(e) => {
          e.stopPropagation();
        }}
        autoFocus
      />

      <span className="edit-entity-actions">
        <i className="bi bi-slash-circle cancel" onMouseDown={handleCancel} />
        <i className="bi bi-check-lg submit" onMouseDown={handleEnter} />
      </span>
    </span>
  );
};

export default memo(EditEntityNumber);
