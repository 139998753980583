import { useCallback, useEffect } from "react";
import { Annotation } from "../interfaces/annotation";
import { useAppSelector } from "../../app";

const useScrollToAnnotation = (annotation: Annotation) => {
  const { activeEntity } = useAppSelector((state) => state.appReducer);

  const scrollAnnotationIntoView = useCallback(
    (annotation: Annotation) => {
      if (!activeEntity) {
        return;
      }

      if (
        activeEntity.entityType.id === annotation.entity.id &&
        activeEntity.index === annotation.index
      ) {
        const container = document.getElementById("annotator-pages-container");
        const target = document.getElementById(
          `${annotation.id}-${annotation.index}-0`
        );

        if (!container || !target) {
          return;
        }

        const targetPosition = target.getBoundingClientRect();

        const scrollTop = Math.max(
          0,
          targetPosition.top - window.innerHeight / 2
        );

        container.scrollTo({
          top: scrollTop,
          left: 0,
          behavior: "smooth", // Optional: adds smooth scrolling
        });
      }
    },
    [activeEntity]
  );

  useEffect(() => {
    scrollAnnotationIntoView(annotation);
  }, [annotation, scrollAnnotationIntoView]);
};

export default useScrollToAnnotation;
