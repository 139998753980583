import React, { FC, memo, useCallback, useRef, useState } from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import useAnnotations from "../../../../annotation";
import { GroupBlockEntityType } from "../../../";
import { useAppDispatch } from "../../../../app";
import { changeEditing } from "../../../../app/store/appSlice";

const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

type EditEntityDateProps = {
  entityType: GroupBlockEntityType;
  documentId: string;
  annotationId: string;
  defaultValue: Dayjs | null;
  groupBlockIndex: number;
  format: string | null;
};

const EditEntityDate: FC<EditEntityDateProps> = ({
  entityType,
  documentId,
  annotationId,
  defaultValue,
  groupBlockIndex,
  format,
}: EditEntityDateProps) => {
  const dispatch = useAppDispatch();

  const dateFormat = format ?? DEFAULT_DATE_FORMAT;

  const isCancelled = useRef(false);
  const [value, setValue] = useState<Dayjs>(
    defaultValue?.isValid() ? defaultValue : dayjs()
  );
  const { updateAnnotation } = useAnnotations({
    documentId,
    groupBlockIndex,
  });

  const handleChange = useCallback(
    (date: Dayjs | null): void => {
      dispatch(changeEditing(null));
      if (date?.isValid()) {
        setValue(date);
        updateAnnotation(
          annotationId,
          date.format(dateFormat),
          entityType,
          true,
          false
        );
      }
    },
    [dispatch, annotationId, updateAnnotation, entityType, dateFormat]
  );

  const handleEnter = (event: any) => {
    event?.stopPropagation();
    dispatch(changeEditing(null));
    if (!isCancelled.current) {
      updateAnnotation(
        annotationId,
        value.format(dateFormat),
        entityType,
        true,
        false
      );
    }
  };

  const handleCancel = (event: any) => {
    event.stopPropagation();
    isCancelled.current = true;
    dispatch(changeEditing(null));
  };

  return (
    <span className="edit-entity edit-entity-date">
      <div
        className="date-picker-wrapper"
        onClick={(e) => {
          console.log(e);
          e.stopPropagation();
        }}
      >
        <DatePicker
          size={"small"}
          onChange={handleChange}
          defaultValue={defaultValue?.isValid() ? dayjs(defaultValue) : dayjs()}
          format={dateFormat}
          allowClear={false}
          open={true}
          picker={"week"}
        />
      </div>
      <span className="edit-entity-actions">
        <i className="bi bi-slash-circle cancel" onMouseDown={handleCancel} />
        <i className="bi bi-check-lg submit" onMouseDown={handleEnter} />
      </span>
    </span>
  );
};

export default memo(EditEntityDate);
