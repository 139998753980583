export const DEFAULT_TABLE_CONTROLS_HEIGHT = 250;
export const MAX_TABLE_CONTROLS_HEIGHT = window.innerHeight / 2;
export const MIN_TABLE_CONTROLS_HEIGHT = 35;

export const TABLE_HEADER_HEIGHT = 35;
export const GROUPBLOCK_TABS_HEIGHT = 30;
export const ROW_HEIGHT = 30;
export const DEFAULT_CELL_WIDTH = 200;
export const HOTKEY_WIDTH = 25;
export const REMOVE_ICON_WIDTH = 15;
export const PERCENTAGE_WIDTH = 70;
export const EDIT_ICONS_WIDTH = 55;
