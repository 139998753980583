import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app";
import { mapData } from "../utils/dataMapper";
import { Role } from "../interfaces/role";
import { OrganizationInput, TableData } from "../interfaces/table";
import { fetchedMemberList } from "../store/UserManagementSlice";
import { useDeleteUserFromOrgApiMutation } from "../store/UserManagementApi";
import { ADMIN_ROLE_ID } from "../../common/utilities/env";

const useOrganization = (filteredData?: Array<TableData>) => {
  const dispatch = useDispatch();

  const { members, organizationSources, validRoles } = useAppSelector(
    (state) => state.userManagementReducer
  );

  const { user } = useAppSelector((state) => state.userReducer);

  const [deleteUserFromOrgApi] = useDeleteUserFromOrgApiMutation();

  const removeMember = useCallback(
    async (userId: string) => {
      if (!user?.access.includes(ADMIN_ROLE_ID)) {
        return;
      }

      if (!user?.organization.id) {
        return;
      }

      try {
        const newMemberList = members.filter((m) => m.id !== userId);
        await deleteUserFromOrgApi({
          organizationId: user.organization.id,
          memberId: userId,
        }).unwrap();

        dispatch(fetchedMemberList(newMemberList));
      } catch (e) {
        console.log(e);
      }
    },
    [dispatch, user, members, deleteUserFromOrgApi]
  );

  const membersExport = useMemo(() => {
    if (!filteredData) {
      return [];
    }

    return filteredData.map((member) => {
      return {
        Name: `${member.firstName} ${member.lastName}`,
        Email: member.email,
        Sources: mapData(member.permissions, organizationSources, "id").map(
          (s: OrganizationInput) => s.displayName
        ),
        Roles: mapData(member.roles, validRoles, "id").map((r: Role) => r.name),
      };
    });
  }, [filteredData, validRoles, organizationSources]);

  return { members, removeMember, membersExport };
};

export default useOrganization;
