import React, { ChangeEvent, FC, memo, useRef, useState } from "react";
import { Input } from "antd/lib";
import useAnnotations from "../../../../annotation";
import { GroupBlockEntityType } from "../../../";
import { useAppDispatch } from "../../../../app";
import {
  addBlurredEntity,
  changeEditing,
} from "../../../../app/store/appSlice";
import { isEmpty } from "../../../../common/utilities/value";

type EditEntityTextProps = {
  entityType: GroupBlockEntityType;
  documentId: string;
  annotationId: string;
  defaultValue: string;
  groupBlockIndex: number;
};

const EditEntityText: FC<EditEntityTextProps> = ({
  entityType,
  documentId,
  annotationId,
  defaultValue,
  groupBlockIndex,
}: EditEntityTextProps) => {
  const dispatch = useAppDispatch();

  const isCancelled = useRef(false);
  const [value, setValue] = useState<string>(defaultValue);
  const { updateAnnotation } = useAnnotations({
    documentId,
    groupBlockIndex,
  });

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setValue(target.value);
  };

  const handleEnter = (event: any) => {
    event.stopPropagation();
    dispatch(changeEditing(null));
    if (!isEmpty(value) && !isCancelled.current) {
      updateAnnotation(annotationId, value, entityType, true, false);
    }
  };

  const onBlur = (event: any) => {
    handleEnter(event);
    dispatch(
      addBlurredEntity(`${entityType.entityType.id}-${groupBlockIndex}`)
    );
  };

  const handleCancel = (event: any) => {
    event.stopPropagation();
    isCancelled.current = true;
    dispatch(changeEditing(null));
  };

  return (
    <span className="edit-entity edit-entity-text">
      <Input
        size={"small"}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        onPressEnter={handleEnter}
        onBlur={onBlur}
        autoFocus
        onClick={(e) => e.stopPropagation()}
      />
      <span className="edit-entity-actions">
        <i className="bi bi-slash-circle cancel" onMouseDown={handleCancel} />
        <i className="bi bi-check-lg submit" onMouseDown={handleEnter} />
      </span>
    </span>
  );
};

export default memo(EditEntityText);
