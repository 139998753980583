import React, { FC } from "react";
import "./Analytics.scss";
import { useAppSelector } from "../../app";
import { Navigate } from "react-router-dom";
import FilterHeader from "./filterHeader/FilterHeader";
import MetricGraphs from "./metricGraphs/MetricGraphs";
import useDocumentSetMetrics from "../hooks/useDocumentSetMetrics";
import useInitMetricsMap from "../hooks/useInitMetricsMap";
import { METRICS_ROLE_ID } from "../../common/utilities/env";

const Analytics: FC = () => {
  const user = useAppSelector((state) => state.userReducer?.user);

  const {
    metricsData,
    loading,
    startDate,
    endDate,
    isBySource,
    showNoMetricsAlert,
  } = useDocumentSetMetrics();

  useInitMetricsMap();

  if (!user?.access?.includes(METRICS_ROLE_ID)) {
    return <Navigate to="/" />;
  }

  return (
    <div className="analytics__container">
      <FilterHeader startDate={startDate} endDate={endDate} loading={loading} />
      <MetricGraphs
        metrics={metricsData}
        loading={loading}
        isBySource={isBySource}
        showNoMetricsAlert={showNoMetricsAlert}
      />
    </div>
  );
};

export default Analytics;
