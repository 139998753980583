import React, { FC } from "react";
import "./FilterHeader.scss";
import MetricsFilterItem from "./metricsFilterItem/MetricsFilterItem";
import { useAppSelector } from "../../../app";
import RangeDatePicker from "./rangeDatePicker/RangeDatePicker";
import SourceFilter from "./sourceFilter/SourceFilter";

type Props = {
  loading: boolean;
  startDate: Date;
  endDate: Date;
};

const FilterHeader: FC<Props> = ({ loading, startDate, endDate }) => {
  const { metricsMap } = useAppSelector((state) => state.appReducer);

  return (
    <div className="filter-header__container">
      <RangeDatePicker
        loading={loading}
        startDate={startDate}
        endDate={endDate}
      />
      <SourceFilter loading={loading} />

      {[...metricsMap]
        .sort(
          (a, b) =>
            (a.index ?? Number.MAX_VALUE) - (b.index ?? Number.MAX_VALUE)
        )
        .map((metricFilterItem) => (
          <div key={metricFilterItem.key} className="filter-item__container">
            <MetricsFilterItem
              filterItem={metricFilterItem}
              loading={loading}
            />
          </div>
        ))}
    </div>
  );
};

export default FilterHeader;
