import React, { FC, MutableRefObject, ReactNode, useMemo, useRef } from "react";
import { DatePicker } from "antd";
import { ColumnItem } from "../../../interfaces/overview";
import useDateFilterItem from "../../../hooks/useDateFilterItem";
import { DATE_FILTER_FORMAT } from "../../../constants";
import useFocus from "../../../hooks/useFocus";
import { OverviewType } from "../../../../app";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";

const { RangePicker } = DatePicker;

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  renderRef: MutableRefObject<any>;
  isOpen: boolean;
  overviewType: OverviewType;
};

const DateFilterItem: FC<Props> = ({
  columnItem,
  label,
  renderRef,
  isOpen,
  overviewType,
}) => {
  const ref: MutableRefObject<null> = useRef(null);
  const { handleBlur, handleFocus, open } = useFocus(ref, isOpen);

  const { getValueFromKey } = useTableHeaderFilter(columnItem, overviewType);

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(columnItem?.metadata, overviewType),
      columnItem?.metadata?.filterAble!.delimiter
    );
  }, [columnItem?.metadata, getValueFromKey, overviewType]);

  const {
    checkboxValue,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { onChange, rangeDates } = useDateFilterItem(
    columnItem,
    overviewType,
    showBlankEntriesCheckbox,
    blankValue,
    values
  );

  return (
    <div className="filter-popover__item">
      {label}
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={checkboxValue}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <RangePicker
        open={open}
        ref={ref}
        className="filter-item range-picker"
        format={DATE_FILTER_FORMAT}
        value={rangeDates}
        allowClear
        onChange={onChange}
        allowEmpty={[false, true]}
        getPopupContainer={() => renderRef.current}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </div>
  );
};

export default DateFilterItem;
