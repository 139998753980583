import { useMemo } from "react";
import { GroupBlockData, useMultipleGroupBlocks } from "../../configMap";
import {
  CategorizationColumnItem,
  EntityColumnItem,
  GroupBlockTable,
  GroupBlockTableData,
} from "../interfaces/groupBlockTable";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import { useTranslation } from "react-i18next";

const useGroupBlockForTable = (
  selectedGroupBlock: GroupBlockData | undefined,
  disabled: boolean
) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { countPerGroupBlockId } = useMultipleGroupBlocks();

  const categorizationColumnItems: Array<CategorizationColumnItem> =
    useMemo(() => {
      if (!selectedGroupBlock) {
        return [];
      }

      const { categorizationGroupBlocks } = selectedGroupBlock;

      const categorizationsSorted = [...categorizationGroupBlocks].sort(
        (a, b) =>
          (a.categorization.index ?? Number.MAX_VALUE) -
          (b.categorization.index ?? Number.MAX_VALUE)
      );

      return categorizationsSorted.map((categorizationWithConfig) => {
        const name = getInLanguageOrDefault(
          categorizationWithConfig.categorization.label,
          language
        );
        return {
          title: `${name} ${categorizationWithConfig.required ? "*" : ""}`,
          key: categorizationWithConfig.id,
          width: "200px",
          disabled: disabled,
          categorizationWithConfig: categorizationWithConfig,
          groupBlockId: selectedGroupBlock.id,
          categorizationCellKey: categorizationWithConfig.categorization.id,
        };
      });
    }, [language, disabled, selectedGroupBlock]);

  const entityColumnItems: Array<EntityColumnItem> = useMemo(() => {
    if (!selectedGroupBlock) {
      return [];
    }

    let entityTypesSorted = selectedGroupBlock.groupBlockEntityTypes
      ? [...selectedGroupBlock.groupBlockEntityTypes].sort(
          (a, b) => a.index - b.index
        )
      : [];

    return entityTypesSorted.map((groupBlockEntityType) => {
      const name = getInLanguageOrDefault(
        groupBlockEntityType.entityType.translations,
        language
      );
      return {
        title: `${name} ${groupBlockEntityType.required ? "*" : ""}`,
        key: groupBlockEntityType.entityType.id,
        width: "200px",
        disabled: disabled,
        groupBlockEntityType: groupBlockEntityType,
      };
    });
  }, [language, disabled, selectedGroupBlock]);

  const data: Array<GroupBlockTableData> = useMemo(() => {
    if (!selectedGroupBlock) {
      return [];
    }

    const count = countPerGroupBlockId(selectedGroupBlock.id);

    return Array(count)
      .fill(0)
      .map((_, index) => {
        return { index: index + 1, groupBlock: selectedGroupBlock, count };
      });
  }, [countPerGroupBlockId, selectedGroupBlock]);

  const selectedGroupBlockForTable: GroupBlockTable | undefined =
    useMemo(() => {
      if (!selectedGroupBlock) {
        return undefined;
      }

      return {
        groupBlock: selectedGroupBlock,
        entityColumnItems,
        categorizationColumnItems,
        data,
      };
    }, [
      selectedGroupBlock,
      categorizationColumnItems,
      entityColumnItems,
      data,
    ]);

  return selectedGroupBlockForTable;
};

export default useGroupBlockForTable;
