import React, { FC } from "react";
import { GraphLineMetrics } from "../../../interfaces/documentSetMetrics";
import lodash from "lodash";
import { SOURCE_COLORS } from "../../../constants";
import "./AverageNumber.scss";

type Props = {
  title: string;
  metrics: Array<GraphLineMetrics> | undefined;
  valueSuffix?: string;
};

const AverageNumber: FC<Props> = ({ title, metrics, valueSuffix }) => {
  if (!metrics) {
    return null;
  }

  const groupedAverage = lodash
    .chain(metrics)
    .groupBy("name")
    .map((entries, name) => [
      name,
      lodash.meanBy(entries, (entry) => entry.value),
    ])
    .fromPairs()
    .value();

  if (!Object.keys(groupedAverage).length) {
    return null;
  }

  return (
    <div className="average-number__container">
      <h3>{title}</h3>
      <ul className="average-number__list">
        {Object.keys(groupedAverage).map((key, index) => {
          const data = groupedAverage[key];
          const color = SOURCE_COLORS[index];
          return (
            <li className="average-number__item" key={key} style={{ color }}>
              <span className="average-number__item-key">{key}</span>
              <span className="average-number__item-value">
                {lodash.round(data, 2)}
                {valueSuffix}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AverageNumber;
