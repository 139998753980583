import React, { useEffect, useState } from "react";
import "./Login.scss";
import { useTranslation } from "react-i18next";
import Button from "../../../common/button/Button";
import ButtonType from "../../../common/button/ButtonTypes";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import TwoSidePage from "../twoSidePage/TwoSidePage";
import { PreviousLocationState } from "../../../app/routes";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";

const Login = () => {
  const { t } = useTranslation("login");

  // @ts-ignore
  const { state, pathname } = useLocation<PreviousLocationState>();

  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated, isLoading } = useIsAuthenticated();

  const [previousPath, setPreviousPath] = useState("");

  useEffect(() => {
    if (!state?.previousPath) {
      return;
    }

    if (state.previousPath !== "/login") {
      setPreviousPath(state.previousPath);
    }
  }, [state]);

  if (isAuthenticated) {
    return <Navigate to="/" state={{ previousPath: pathname }} />;
  }

  return (
    <TwoSidePage>
      <div className="login-page__container">
        <h3 className="welcome">{t("welcome-back")}</h3>
        <h2 className="login-to-account">{t("login")}</h2>
        <Button
          handleClick={() =>
            loginWithRedirect({
              appState: {
                returnTo: previousPath,
              },
            })
          }
          className="sign-in-button"
          buttonType={ButtonType.Primary}
          text="Log in"
          htmlType="submit"
          disabled={isLoading}
          icon={<i className="bi bi-box-arrow-in-right sign-in-button__icon" />}
        />
      </div>
    </TwoSidePage>
  );
};

export default Login;
