// @ts-ignore
import humanizeDuration from "humanize-duration";

export const humanizeTime = (value: string, language: string): string => {
  const milliseconds = parseFloat(value) * 1000;
  return humanizeDuration(milliseconds, {
    language,
    fallbacks: ["en"],
    round: true,
  });
};
