import React, { FC } from "react";
import {
  Alert,
  Button,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tag,
} from "antd";
import useManagementModal from "../../hooks/useManagementModal";
import { ColumnsType } from "antd/es/table";
import { MemberRole } from "../../interfaces/table";
import "./UserManagementModal.scss";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../../../common/utilities/color";
import useOrganization from "../../hooks/useOrganization";

const UserManagementModal: FC = () => {
  const { t } = useTranslation("userManagement");

  const {
    member,
    stopEditingMember,
    roles,
    handleCheckedChange,
    userInputs,
    handlePermissionsChange,
    options,
    handleSearch,
  } = useManagementModal();

  const { removeMember } = useOrganization([]);

  const columns: ColumnsType<MemberRole> = [
    {
      title: t("role"),
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      render: (_, { name }) => t(`roles.${name}`),
      width: "60%",
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (_, { id, isActive }) => (
        <Switch
          checkedChildren={<i className="bi bi-check" />}
          unCheckedChildren={<i className="bi bi-x-lg" />}
          checked={isActive}
          onChange={(checked: boolean) => handleCheckedChange(checked, id)}
        />
      ),
    },
  ];

  return (
    <Modal
      title={
        <div className="user-management-modal__title">
          <h2>{t("modal.header")}</h2>
          <Tag color={primaryColor}>{member?.email}</Tag>
        </div>
      }
      centered
      open={!!member}
      footer={null}
      onCancel={stopEditingMember}
      width={600}
      wrapClassName="user-management-modal__container"
    >
      <Alert
        description={t("modal.warningAlert")}
        type="warning"
        className="user-management-modal__alert"
      />
      <div className="header-accent__container">
        <h3>{t("modal.header2")}</h3>
      </div>
      <Table
        className="user-management-modal__table"
        columns={columns}
        dataSource={roles}
        pagination={false}
        rowKey={(record) => record.id}
      />
      <div className="header-accent-permissions__container">
        <h3>{t("modal.header3")}</h3>
      </div>
      <Select
        className="user-management-modal-permissions__select"
        mode="multiple"
        allowClear={true}
        style={{ width: "100%" }}
        placeholder="Please select"
        onChange={handlePermissionsChange}
        value={userInputs}
        options={options}
        onSearch={handleSearch}
        filterOption={false}
      />
      <Popconfirm
        title={t("modal.deleteMember.title")}
        description={t("modal.deleteMember.description")}
        onConfirm={() => removeMember(member?.id!)}
        okText="Yes"
        cancelText="No"
        icon={
          <span
            className="bi bi-exclamation-circle"
            style={{ marginRight: "5px", color: "red" }}
          />
        }
      >
        <Button icon={<i className="bi bi-trash" />} danger>
          {t("modal.removeButton")}
        </Button>
      </Popconfirm>
    </Modal>
  );
};

export default UserManagementModal;
