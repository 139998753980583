import React, { FC, useMemo } from "react";
import "./StackedColumnLineGraph.scss";
import { DualAxesChart } from "@opd/g2plot-react";
import { GraphLineMetrics } from "../../../interfaces/documentSetMetrics";
import { LINE_GRAPH_HEIGHT } from "../../../constants";
import { LineSkeletonLoader } from "../lineGraph/lineSkeletonLoader/lineSkeletonLoader";

type Props = {
  title: string;
  metrics: Array<GraphLineMetrics> | undefined;
  loading: boolean;
};

const StackedColumnLineGraph: FC<Props> = ({ title, metrics, loading }) => {
  const metricsTransformed = useMemo(() => {
    if (loading) {
      return [[], []];
    }

    if (!metrics?.length) {
      return [[], []];
    }

    return [
      metrics.flatMap((m) => {
        return [
          {
            createdDate: m.createdDate,
            count: m.averageNumberOfOrderLines,
            type: "Orderlines",
          },
          {
            createdDate: m.createdDate,
            count: m.value,
            type: "Changes",
          },
        ];
      }),
      metrics.flatMap((m) => {
        return [
          {
            createdDate: m.createdDate,
            value: m.averageAdditions,
            name: "Additions",
          },
          {
            createdDate: m.createdDate,
            value: m.averageDeletions,
            name: "Deletions",
          },
          {
            createdDate: m.createdDate,
            value: m.averageUpdates,
            name: "Updates",
          },
        ];
      }),
    ];
  }, [loading, metrics]);

  if (loading) {
    return <LineSkeletonLoader />;
  }

  if (!metrics?.length) {
    return null;
  }

  return (
    <div className="graph__container">
      <h3>{title}</h3>
      <DualAxesChart
        data={metricsTransformed}
        xField={"createdDate"}
        yField={["count", "value"]}
        geometryOptions={[
          {
            geometry: "column",
            isGroup: true,
            seriesField: "type",
            columnWidthRatio: 0.4,
          },
          {
            geometry: "line",
            seriesField: "name",
          },
        ]}
        height={LINE_GRAPH_HEIGHT}
      />
    </div>
  );
};

export default StackedColumnLineGraph;
