import { OverviewType } from "../../app";
import {
  annotationDoneStatuses,
  annotationTodoStatuses,
  doneStatuses,
  errorStatuses,
  Status,
  statuses,
  todoStatuses,
} from "../../common/status/status";
import { SortDirection, SortState } from "../../common/dataRefining";

export const cleanFilterString = (
  filterString: string,
  overviewType?: OverviewType
): string => {
  if (
    filterString.includes("assignee.id") &&
    filterString.includes("approvedBy.id")
  ) {
    if (overviewType === OverviewType.Archived) {
      return filterString.replace(/(assignee\.id)=[^,]+,?/g, "");
    }

    return filterString.replace(/(approvedBy\.id)=[^,]+,?/g, "");
  }

  return filterString;
};

export const buildFilters = (
  inputIds: Array<string>,
  filterString: string,
  overviewType?: OverviewType,
  includeNonClickableStatuses: boolean = true
) => {
  const filteredTodoStatuses = includeNonClickableStatuses
    ? todoStatuses
    : statuses.slice(0, 3);

  const idFilter = `input.id=${inputIds.join("+")}`;

  if (!filterString?.length) {
    switch (overviewType) {
      case OverviewType.AnnotationTodo:
        return `${idFilter},status=${annotationTodoStatuses.join(
          "+"
        )},error:null`;
      case OverviewType.AnnotationDone:
        return `${idFilter},status=${annotationDoneStatuses.join("+")}`;
      case OverviewType.Archived:
        return `${idFilter},status=${doneStatuses.join("+")}`;
      case OverviewType.Error:
        return `${idFilter},status=${errorStatuses.join("+")}`;
      case OverviewType.Remove:
        return `${idFilter},status=${Status.Remove}`;
      default:
        return `${idFilter},status=${filteredTodoStatuses.join("+")}`;
    }
  }

  if (filterString.includes("status")) {
    if (overviewType === OverviewType.AnnotationTodo) {
      return `${idFilter},${filterString},error:null`;
    }
    return `${idFilter},${filterString}`;
  }

  switch (overviewType) {
    case OverviewType.AnnotationTodo:
      return `${idFilter},status=${annotationTodoStatuses.join(
        "+"
      )},${filterString},error:null`;
    case OverviewType.AnnotationDone:
      return `${idFilter},status=${annotationDoneStatuses.join(
        "+"
      )},${filterString}`;
    case OverviewType.Archived:
      return `${idFilter},status=${doneStatuses.join("+")},${filterString}`;
    case OverviewType.Error:
      return `${idFilter},status=${errorStatuses.join("+")},${filterString}`;
    case OverviewType.Remove:
      return `${idFilter},status=${Status.Remove},${filterString}`;
    default:
      return `${idFilter},status=${filteredTodoStatuses.join(
        "+"
      )},${filterString}`;
  }
};

export const buildSorters = (sorters?: SortState | null) => {
  if (sorters) {
    return `${sorters.key},${
      sorters.sortDirection === SortDirection.Ascending ? "asc" : "desc"
    }`;
  }

  return null;
};

export const getOverviewTypeByStatus = (
  status: Status,
  isAnnotationMode: boolean
): OverviewType => {
  if (!isAnnotationMode) {
    if (
      doneStatuses
        .filter((status) => status !== Status.Archived)
        .includes(status)
    ) {
      return OverviewType.Archived;
    } else if (errorStatuses.includes(status)) {
      return OverviewType.Error;
    } else if (Status.Remove === status) {
      return OverviewType.Remove;
    }
  } else {
    if (
      annotationTodoStatuses
        .filter((status) => status !== Status.Archived)
        .includes(status)
    ) {
      return OverviewType.AnnotationTodo;
    } else if (
      annotationDoneStatuses
        .filter((status) => status !== Status.Archived)
        .includes(status)
    ) {
      return OverviewType.AnnotationDone;
    }
  }

  return OverviewType.Home;
};

export const getPathToRouteByStatus = (
  status: Status,
  isAnnotationMode: boolean
): string => {
  const overviewType = getOverviewTypeByStatus(status, isAnnotationMode);

  switch (overviewType) {
    case OverviewType.AnnotationDone:
      return "/archive";
    case OverviewType.Archived:
      return "/archive";
    case OverviewType.Error:
      return "/errors";
    case OverviewType.Remove:
      return "/remove";
    default:
      return "/";
  }
};
