import React, { FC, useMemo } from "react";
import { Table, TableColumnsType } from "antd";
import { sortAlphabetically } from "../../../../utils/sorting";
import useStpSettings from "../../../../hooks/useStpSettings";
import useTableDataFilter, {
  FuseConfig,
} from "../../../../../common/dataFiltering/useTableDataFilter";
import TableHeaderFilter from "../../../../../common/tableHeaderFilter/TableHeaderFilter";
import StpSupplierTableFooter from "./StpSupplierTableFooter";
import { useAppSelector } from "../../../../../app";
import { IStpSupplierTableData } from "../../../../interfaces/stp";
import TableColumnContentLoader from "../../../../../common/skeletonLoader/tableColumnContentLoader/TableColumnContentLoader";
import { SkeletonLoaderType } from "../../../../../common/skeletonLoader/interfaces/type";
import "./StpSuppliers.scss";
import { useTranslation } from "react-i18next";
import useTableDynamicHeight, {
  TableType,
} from "../../../../../common/table/hooks/useTableDynamicHeight";
import useFetchStpSettings from "../../../../hooks/useFetchStpSettings";
import TitleRowWithContent from "../../../../../common/titleRowWithContent/TitleRowWithContent";
import { OrganizationSettingsMenuKeys } from "../../../../utils/constants";
import sourceOptionsSelector from "../../../../selectors/sourceOptionsSelector";

const SKELETON_LOAD_ROWS = 10;

const emptyFilter: Partial<IStpSupplierTableData> = {
  vat: "",
};

const fuseConfig: FuseConfig<IStpSupplierTableData> = {
  keys: ["supplierId", "vat", "source"],
};

const StpSuppliers: FC = () => {
  const { t } = useTranslation("stp");

  const { suppliers } = useAppSelector(
    (state) => state.organizationReducer.settings.stp
  );

  const sourcesOptions = useAppSelector(sourceOptionsSelector);

  const { selectedRows, setSelectedRows, removeSuppliers } = useStpSettings();

  const { refHeightContainer, heightTableContent } = useTableDynamicHeight(
    TableType.STP
  );

  const { loadingSuppliers: loading } = useFetchStpSettings(
    OrganizationSettingsMenuKeys.STP_SUPPLIERS
  );

  const {
    handleFilterUpdate,
    applyFilter,
    filteredData,
    getValueFromFilterQuery,
  } = useTableDataFilter(suppliers, emptyFilter, fuseConfig, "id");

  const data = useMemo(() => {
    if (loading) {
      return [...Array(SKELETON_LOAD_ROWS)].map((_, index) => ({
        id: index.toString(),
      })) as any;
    }

    return filteredData;
  }, [loading, filteredData]);

  const columns: TableColumnsType<IStpSupplierTableData> = [
    {
      title: "",
      key: "space",
      width: "20px",
    },
    {
      key: "supplierId",
      dataIndex: "supplierId",
      width: "200px",
      showSorterTooltip: true,
      sorter: (a, b) => sortAlphabetically(a.id, b.id),
      title: () => (
        <div className="header-filter__container">
          {t("suppliers.supplierId")}
          <TableHeaderFilter
            prop="supplierId"
            updateFilter={handleFilterUpdate}
            label="Supplier id"
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("supplierId")}
          />
        </div>
      ),
      render: (_, { supplierId }) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          {supplierId}
        </TableColumnContentLoader>
      ),
    },
    {
      key: "vat",
      dataIndex: "vat",
      width: "200px",
      showSorterTooltip: true,
      sorter: (a, b) => sortAlphabetically(a.vat, b.vat),
      title: () => (
        <div className="header-filter__container">
          {t("suppliers.vat")}
          <TableHeaderFilter
            prop="vat"
            updateFilter={handleFilterUpdate}
            label="Vat"
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("vat")}
          />
        </div>
      ),
      render: (_, { vat }) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          {vat}
        </TableColumnContentLoader>
      ),
    },
    {
      key: "source",
      width: "200px",
      showSorterTooltip: true,
      sorter: (a, b) => sortAlphabetically(a.source, b.source),
      title: () => (
        <div className="header-filter__container">
          {t("suppliers.source")}
          <TableHeaderFilter
            prop="source"
            updateFilter={handleFilterUpdate}
            label="Source"
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("source")}
            options={sourcesOptions}
          />
        </div>
      ),
      render: (_, { source }) => {
        const found = sourcesOptions.find(
          (os) => os.value.toLowerCase() === source?.toLowerCase()
        );

        return (
          <TableColumnContentLoader
            loading={loading}
            type={SkeletonLoaderType.LINE}
          >
            {found?.label || source}
          </TableColumnContentLoader>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "20px",
      align: "center",
      render: (_, { id }) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          <div className="delete-button" onClick={() => removeSuppliers([id])}>
            <span className="bi bi-trash" />
          </div>
        </TableColumnContentLoader>
      ),
    },
  ];

  return (
    <div className="stp-suppliers__container" ref={refHeightContainer}>
      <TitleRowWithContent title="STP Suppliers" content={null}>
        <Table
          className="stp-suppliers-table"
          size="small"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: heightTableContent }}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: setSelectedRows,
          }}
          footer={() => (
            <div className="footer__add-button">
              <StpSupplierTableFooter
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                removeSuppliers={removeSuppliers}
              />
            </div>
          )}
        />
      </TitleRowWithContent>
    </div>
  );
};

export default StpSuppliers;
