import { useCallback, useMemo } from "react";
import { RangePickerProps } from "antd/es/date-picker";
import { updateFilterArray } from "../../common/dataRefining/store/dataRefiningSlice";
import dayjs from "dayjs";
import { OverviewType, useAppDispatch } from "../../app";
import { ColumnItem } from "../interfaces/overview";
import { DATES_FILTER_STRING_SEPARATOR } from "../constants";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";

const useDateFilterItem = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  showBlankEntriesCheckbox: boolean,
  blankValue: string,
  values: string | undefined
) => {
  const dispatch = useAppDispatch();

  const { metadata } = columnItem;

  const onChange = useCallback(
    (values: RangePickerProps["value"]) => {
      if (values?.length) {
        const startDate = values[0]?.format("YYYY-MM-DDTHH:mm:ss.SSS");
        const endDate = values[1]?.format("YYYY-MM-DDTHH:mm:ss.SSS");

        let valuesString = startDate ?? "";
        if (endDate) {
          valuesString += `;${endDate}`;
        }

        const updateCalls = buildFilterUpdateCalls(
          valuesString,
          metadata,
          overviewType
        );

        updateCalls.forEach((uc) => {
          dispatch(updateFilterArray(uc));
        });
      } else {
        dispatch(
          updateFilterArray({
            key: metadata?.filterAble!.filterKey,
            delimiter: metadata?.filterAble!.delimiter,
            value: "",
            overviewType: overviewType,
          })
        );
      }
    },
    [dispatch, metadata, overviewType]
  );

  const rangeDates: RangePickerProps["value"] = useMemo(() => {
    if (showBlankEntriesCheckbox && values === blankValue) {
      return undefined;
    }

    if (!values) {
      return undefined;
    }

    let startDate: Date;
    let endDate: Date | null = null;

    const dateSplitted = values?.split(DATES_FILTER_STRING_SEPARATOR);
    startDate = new Date(dateSplitted[0]);

    if (dateSplitted?.length === 2) {
      endDate = new Date(dateSplitted[1]);
    }

    return [dayjs(startDate), endDate && dayjs(endDate)];
  }, [values, showBlankEntriesCheckbox, blankValue]);

  return {
    onChange,
    rangeDates,
  };
};

export default useDateFilterItem;
