import React, { FC } from "react";
import "./Entity.scss";
import { useTranslation } from "react-i18next";
import { EntityAnnotation } from "../../../annotation";
import { GroupBlockEntityType } from "../../index";
import { primaryColor } from "../../../common/utilities/color";
import { useAppSelector } from "../../../app";
import { RAVAGO_COMPANY_NAME } from "../../../common/constants";
import SingleAnnotation from "./SingleAnnotation";
import HoverButtons from "./HoverButtons/HoverButtons";
import Loader from "../../../common/loader/Loader";
import { Status } from "../../../common/status/status";

type Props = {
  entityAnnotations: Array<EntityAnnotation> | undefined;
  color: string;
  removeAnnotation: (annotationId: string) => void;
  disabled: boolean;
  entityType: GroupBlockEntityType;
  isEditing: boolean;
  isAnnotating: boolean;
  groupBlockIndex: number;
  requiresAttention: boolean;
  documentId?: string;
  handleEntityClick: () => void;
};

const MultipleAnnotations: FC<Props> = ({
  entityAnnotations,
  color,
  removeAnnotation,
  disabled,
  entityType,
  isEditing,
  isAnnotating,
  groupBlockIndex,
  documentId,
  handleEntityClick,
  requiresAttention,
}) => {
  return (
    <div
      className="multiple-annotations"
      style={{ color: color || primaryColor }}
    >
      <ul>
        <li>&nbsp;</li>
        {(entityAnnotations || []).map((entityAnnotation: EntityAnnotation) => {
          const { index, isLoading } = entityAnnotation;
          const isFirstAnnotation = index === 0;

          return (
            <li
              className="li__annotation"
              key={index}
              onClick={() => isFirstAnnotation && handleEntityClick()}
            >
              <span className="li__dot" style={{ background: color }} />
              <div className="entity-annotation__annotation">
                <div>
                  <SingleAnnotation
                    requiresAttention={requiresAttention}
                    documentId={documentId}
                    entityType={entityType}
                    entityAnnotation={entityAnnotation}
                    isEditing={isEditing}
                    isAnnotating={isAnnotating}
                    groupBlockIndex={groupBlockIndex}
                    disabled={disabled}
                    showLabels={isFirstAnnotation}
                    isEditable={isFirstAnnotation}
                    showNormalizedValue={isFirstAnnotation}
                  />
                </div>
                <div>
                  {isLoading ? (
                    <div className="loader">
                      <Loader size="small" />
                    </div>
                  ) : (
                    !isEditing &&
                    !disabled && (
                      <HoverButtons
                        showEditIcon={isFirstAnnotation}
                        showRemoveIcon={true}
                        onClickRemove={(event) => {
                          event.stopPropagation();
                          removeAnnotation(entityAnnotation.id!);
                        }}
                      />
                    )
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <MultipleAnnotationsError />
    </div>
  );
};

const MultipleAnnotationsError: FC = () => {
  const { t } = useTranslation("detail");

  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const user = useAppSelector((state) => state.userReducer.user);

  if (
    activeDocumentSet &&
    ![Status.ToDo, Status.OnHold, Status.InProgress].includes(
      activeDocumentSet.status
    )
  ) {
    return null;
  }

  if (
    user?.organization?.name?.toLowerCase() &&
    user?.organization?.name?.toLowerCase().includes(RAVAGO_COMPANY_NAME)
  ) {
    return null;
  }

  return <div className="error-text">{t("multi-annotation-error")}</div>;
};

export default MultipleAnnotations;
