import { Menu, Popover, Switch } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import "./Actions.scss";
import { useTranslation } from "react-i18next";
import useActions from "../../../hooks/useActions";
import { ItemType } from "antd/lib/menu/interface";
import { DrawerType } from "../../../interfaces/drawer";

type Props = {
  disabled: boolean;
};

const Actions: FC<Props> = ({ disabled }) => {
  const { t } = useTranslation("annotationSideControls");

  const {
    toggleHideNonRequiredEntitiesForUser,
    downloadPdfAndClose,
    removeAllAnnotationsAndClose,
    hideNonRequiredEntities,
    open,
    setOpen,
    openDrawerAndSetType,
  } = useActions();

  const items: Array<ItemType> = useMemo(
    () => [
      {
        icon: <i className="bi bi-eraser" />,
        disabled: disabled,
        label: (
          <span className="item-label">
            {t("annotationSideControlsHeader.general")}
          </span>
        ),
        type: "group",
        key: "clearAllAnnotations",
        onClick: removeAllAnnotationsAndClose,
        children: [
          {
            icon: <i className="bi bi-info-circle" />,
            label: (
              <span className="item-label">
                {t("annotationSideControlsHeader.moreDetails")}
              </span>
            ),
            key: "moreDetails",
            onClick: () => openDrawerAndSetType(DrawerType.DETAILS),
          },
          // {
          //   icon: <i className="bi bi-clock-history" />,
          //   label: (
          //     <span className="item-label">
          //       {t("annotationSideControlsHeader.annotationEditHistory")}
          //     </span>
          //   ),
          //   key: "annotationHistory",
          //   onClick: () => openDrawerAndSetType(DrawerType.ANNOTATION_HISTORY),
          // },
          {
            icon: <i className="bi bi-download" />,
            label: "Download",
            key: "downloadFile",
            onClick: downloadPdfAndClose,
          },
        ],
      },
      {
        icon: <i className="bi bi-eraser" />,
        disabled: disabled,
        label: (
          <span className="item-label">
            {t("annotationSideControlsHeader.annotations")}
          </span>
        ),
        type: "group",
        key: "annotations",
        onClick: removeAllAnnotationsAndClose,
        children: [
          {
            icon: <i className="bi bi-eraser" />,
            disabled: disabled,
            label: (
              <span className="item-label">
                {t("annotationSideControlsHeader.clearAllAnnotations")}
              </span>
            ),
            key: "clearAllAnnotations",
            onClick: removeAllAnnotationsAndClose,
          },
          {
            icon: <i className="bi bi-eye-slash" />,
            disabled: disabled,
            label: (
              <>
                <span className="switch-label">
                  {t("groupBlocksHeader.entities")}
                </span>
                <Switch disabled={disabled} checked={hideNonRequiredEntities} />
              </>
            ),
            key: "hideNonRequiredEntities",
            onClick: toggleHideNonRequiredEntitiesForUser,
          },
        ],
      },
    ],
    [
      disabled,
      hideNonRequiredEntities,
      removeAllAnnotationsAndClose,
      downloadPdfAndClose,
      openDrawerAndSetType,
      t,
      toggleHideNonRequiredEntitiesForUser,
    ]
  );

  const content = useCallback(() => {
    return <Menu selectable={false} className="popover-menu" items={items} />;
  }, [items]);

  return (
    <Popover
      className="actions"
      open={open}
      placement="rightTop"
      content={content}
      trigger="click"
      onOpenChange={() => setOpen(!open)}
    >
      <i className="bi bi-three-dots-vertical" />
    </Popover>
  );
};

export default Actions;
