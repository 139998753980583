import React, { FC } from "react";
import "./LineGraph.scss";
import { LineChart } from "@opd/g2plot-react";
import { GraphLineMetrics } from "../../../interfaces/documentSetMetrics";
import { LINE_GRAPH_HEIGHT, SOURCE_COLORS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { renderToStaticMarkup } from "react-dom/server";
import ToolTip from "./Tooltip/Tooltip";
import { GraphEvent } from "../../../interfaces/graph";
import { LineSkeletonLoader } from "./lineSkeletonLoader/lineSkeletonLoader";

type Props = {
  title: string;
  metrics: Array<GraphLineMetrics> | undefined;
  loading: boolean;
  isTimeGraph?: boolean;
  lineGraphRef?: React.MutableRefObject<any>;
  handleLegendClick?: (e: any) => void;
  tooltipSuffix?: string;
};

const LineGraph: FC<Props> = ({
  title,
  metrics,
  loading,
  isTimeGraph = false,
  lineGraphRef,
  handleLegendClick,
  tooltipSuffix,
}) => {
  const {
    i18n: { language },
  } = useTranslation("analytics");

  if (loading) {
    return <LineSkeletonLoader />;
  }

  if (!metrics?.length) {
    return null;
  }

  return (
    <div className="graph__container">
      <h3>{title}</h3>
      <LineChart
        chartRef={lineGraphRef}
        data={loading ? [] : metrics!}
        xField={"createdDate"}
        yField={"value"}
        seriesField={"name"}
        height={LINE_GRAPH_HEIGHT}
        color={SOURCE_COLORS}
        smooth={false}
        legend={{ position: "top" }}
        point={{ size: 3 }}
        tooltip={{
          customContent: (title, data) =>
            renderToStaticMarkup(
              <ToolTip
                title={title}
                data={data}
                isTime={isTimeGraph}
                language={language}
                suffix={tooltipSuffix}
              />
            ),
        }}
        onReady={(c) => {
          c.on("legend:click", (e: GraphEvent) => {
            if (handleLegendClick) {
              handleLegendClick(e);
            }
          });
        }}
      />
    </div>
  );
};

export default LineGraph;
