import { useCallback, useState } from "react";
import { RangePickerProps } from "antd/es/date-picker";
import { useAppDispatch } from "../../app";
import {
  setMetricsDatePeriod,
  updateMetricsDates,
} from "../../common/dataRefining/store/dataRefiningSlice";
import dayjs from "dayjs";
import { MetricsDatePeriod } from "../constants";
import { triggerRefreshMetrics } from "../store/analyticsSlice";

const useRangeDatePicker = (startDate: Date, endDate: Date) => {
  const [rangeDates, setRangeDates] = useState<RangePickerProps["value"]>([
    dayjs(startDate),
    dayjs(endDate),
  ]);

  const dispatch = useAppDispatch();

  const onClose = useCallback(
    (values: RangePickerProps["value"]) => {
      if (values?.length && values[0] && values[1]) {
        const startDate = values[0].toDate();
        const endDate = values[1].toDate();
        dispatch(
          updateMetricsDates({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          })
        );
        dispatch(setMetricsDatePeriod(MetricsDatePeriod.CUSTOM));
        dispatch(triggerRefreshMetrics());
      }
    },
    [dispatch]
  );

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (open || !rangeDates?.length) {
        return;
      }

      const newStart = rangeDates[0]?.toDate();
      const newEnd = rangeDates[1]?.toDate();

      if (
        startDate.toLocaleDateString() === newStart?.toLocaleDateString() &&
        endDate.toLocaleDateString() === newEnd?.toLocaleDateString()
      ) {
        return;
      }

      onClose(rangeDates);
    },
    [onClose, startDate, endDate, rangeDates]
  );

  const onChange = useCallback((value: RangePickerProps["value"]) => {
    setRangeDates(value);
  }, []);

  return {
    onOpenChange,
    onChange,
  };
};

export default useRangeDatePicker;
