import React, { FC } from "react";
import { PieChart } from "@opd/g2plot-react";
import {
  GraphPieMetrics,
  SourceColor,
} from "../../../interfaces/documentSetMetrics";
import { OTHER_SOURCE_COLOR, PIE_GRAPH_HEIGHT } from "../../../constants";
import "./PieGraph.scss";
import { useTranslation } from "react-i18next";
import { renderToStaticMarkup } from "react-dom/server";
import Tooltip from "./Tooltip/Tooltip";
import { GraphEvent } from "../../../interfaces/graph";
import { PieSkeletonLoader } from "./pieSkeletonLoader/pieSkeletonLoader";

export const VISIBLE_PERCENTAGE = 0.02;

type Props = {
  metrics?: Array<GraphPieMetrics>;
  sourceColors?: Array<SourceColor>;
  loading: boolean;
  pieGraphRef?: React.MutableRefObject<any>;
  handleElementMouseOver?: (e: any) => void;
  handleElementMouseLeave?: () => void;
};

const PieGraph: FC<Props> = ({
  metrics,
  sourceColors,
  loading,
  pieGraphRef,
  handleElementMouseOver,
  handleElementMouseLeave,
}) => {
  const { t } = useTranslation("analytics");

  if (loading) {
    return <PieSkeletonLoader />;
  }

  if (!metrics?.length) {
    return null;
  }

  return (
    <div className="pie-graph__container">
      <PieChart
        chartRef={pieGraphRef}
        height={PIE_GRAPH_HEIGHT}
        data={loading ? [] : metrics!}
        angleField={"total"}
        colorField={"name"}
        innerRadius={0.56}
        label={{
          type: "inner",
          offset: "-50%",
          content: ({ percent }: any) => {
            if (percent < VISIBLE_PERCENTAGE) {
              return "";
            }
            return `${(percent * 100).toFixed(1)}%`;
          },
          style: {
            fontSize: 12,
            textAlign: "center",
          },
        }}
        tooltip={{
          customContent: (_title, data) =>
            renderToStaticMarkup(
              <Tooltip data={data} sourceColors={sourceColors} />
            ),
        }}
        statistic={{
          content: {
            style: {
              fontSize: "25",
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            },
          },
          title: {
            content: t("pieGraph.totalLabel"),
            style: {
              display: loading ? "none" : "block",
            },
          },
        }}
        legend={false}
        color={(item) => {
          const found = sourceColors?.find((sc) => sc.source === item.name);
          if (found) {
            return found.color;
          }
          return OTHER_SOURCE_COLOR;
        }}
        onReady={(c) => {
          c.on("element:mouseover", (e: GraphEvent) => {
            if (handleElementMouseOver) {
              handleElementMouseOver(e);
            }
          });
          c.on("element:mouseleave", () => {
            if (handleElementMouseLeave) {
              handleElementMouseLeave();
            }
          });
        }}
        interactions={[
          {
            type: "element-highlight",
            cfg: {
              end: [
                { trigger: "element:mouseleave", action: "cursor:default" },
                {
                  trigger: "element:mouseleave",
                  action: "element-highlight:reset",
                },
              ],
            },
          },
        ]}
      />
    </div>
  );
};

export default PieGraph;
