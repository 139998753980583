import { Translation } from "./configmap";
import { IEntityTypeSimple } from "./entity";
import { SearchQuery } from "../../documentSet/interfaces/api";

export interface ICategory {
  id: string;
  visibleValue: string;
  value: string;
}

export interface ICategorization {
  id: string;
  index: number;
  label: Translation;
  search: SearchQuery;
  categories: Array<ICategory>;
  entityTypes: Array<IEntityTypeSimple>;
  setType: string;
}

export interface CategorizationWithConfig {
  id: string;
  categorization: ICategorization;
  required?: boolean;
}

export enum CategorizationSetType {
  FIXED = "FIXED",
  DYNAMIC = "DYNAMIC",
}

export interface DocumentCategoryAnnotation {
  documentId: string;
  categorizationId: string;
  categoryId: string;
  categoryValue?: string;
  categoryVisibleValue?: string;
  groupBlockId?: string;
  index: number;
  isByUser?: boolean;
}

export interface DocumentCategoryAnnotationCreate {
  documentId: string;
  categorizationId: string;
  categoryId: string;
  categoryValue?: string;
  categoryVisibleValue?: string;
  groupBlockId: string | null;
  index: number;
}

export interface DocumentCategoryAnnotationRemove {
  documentId: string;
  categorizationId: string;
  groupBlockId?: string;
  index: number;
}

export interface DocumentCategoryAnnotationRemoveByGroupBlockIndex {
  documentId: string;
  categorizations: Array<ICategorization>;
  groupBlockIndex: number;
  isGroupBlockRemoved: boolean;
}

export interface DocumentCategoryAnnotationRemoveByCategorizations {
  documentId: string;
  categorizations: Array<ICategorization>;
}

export interface UpdateMultiGroupBlockDocumentCategoryAnnotation {
  categorizations: Array<ICategorization>;
  groupBlockIndex: number;
  addBefore?: boolean;
}

export interface CategorizationState {
  documentCategoryAnnotations: Array<DocumentCategoryAnnotation>;
}
