import React, { FC, ReactNode } from "react";
import klassifLogo from "../../../common/assets/klassifaiWhite.svg";
import { useTranslation } from "react-i18next";
import "./TwoSidePage.scss";

type Props = {
  children: ReactNode;
};

const TwoSidePage: FC<Props> = ({ children }) => {
  const { t } = useTranslation("login");

  return (
    <div className="two-side-page__container">
      <div className="left-side">
        <div className="left-side-content">
          <img
            className="klassifai-logo"
            src={klassifLogo}
            alt="Klassifai logo"
          />
          <h1 className="banner-text">{t("banner-text")}</h1>
        </div>
      </div>
      <div className="right-side">
        <div className="right-side-content">{children}</div>
      </div>
    </div>
  );
};

export default TwoSidePage;
