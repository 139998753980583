import { useCallback, useEffect, useMemo, useState } from "react";
import { GroupBlockData } from "../../configMap";
import { RootState, useAppSelector } from "../../app";
import useMeasure from "react-use-measure";
import useGroupBlockForTable from "./useGroupBlockForTable";
import useActionAddLine from "./useActionAddLine";
import useSetGroupBlockTabHeightByLines from "./useSetTableControlsHeight";

const useAnnotationTableControls = (
  disabled: boolean,
  multipleLinesGroupBlocks: Array<GroupBlockData>
) => {
  const configMapLoading = useAppSelector(
    (state) => state.configMapReducer.loading
  );

  const selectedGroupBlockId = useAppSelector(
    (state: RootState) =>
      state.annotationTableControlsReducer.selectedGroupBlockId
  );

  const { isTableControlsResizing } = useAppSelector(
    (state) => state.annotationTableControlsReducer
  );

  const [refHeightContainer, { height }] = useMeasure();

  const [currentHeight, setCurrentHeight] = useState(height);

  const addGroupBlockAction = useActionAddLine(multipleLinesGroupBlocks);

  const { setTableControlsHeightOnAddLine } =
    useSetGroupBlockTabHeightByLines();

  const selectedGroupBlock = useMemo(() => {
    return multipleLinesGroupBlocks.find(
      (gb) => gb.id === selectedGroupBlockId
    );
  }, [selectedGroupBlockId, multipleLinesGroupBlocks]);

  const selectedGroupBlockForTable = useGroupBlockForTable(
    selectedGroupBlock,
    disabled
  );

  const addGroupBlockLineAction = useCallback(
    (groupBlock: GroupBlockData, index: number) => {
      addGroupBlockAction(groupBlock, index);
      setTableControlsHeightOnAddLine();
    },
    [addGroupBlockAction, setTableControlsHeightOnAddLine]
  );

  // Avoid setting the actual height during table control resizing to prevent excessive rendering.
  useEffect(() => {
    if (isTableControlsResizing) {
      return;
    }

    setCurrentHeight(height);
  }, [height, isTableControlsResizing]);

  return {
    selectedGroupBlockForTable,
    selectedGroupBlock,
    addGroupBlockLineAction,
    configMapLoading,
    refHeightContainer,
    currentHeight,
  };
};

export default useAnnotationTableControls;
