import { useCallback } from "react";
import { useMultipleGroupBlocks } from "../../configMap";
import { useDispatch } from "react-redux";
import { RootState, useAppSelector } from "../../app";
import { setTableControlsHeight } from "../store/AnnotationTableControlsSlice";
import {
  DEFAULT_TABLE_CONTROLS_HEIGHT,
  GROUPBLOCK_TABS_HEIGHT,
  ROW_HEIGHT,
  TABLE_HEADER_HEIGHT,
} from "../constants";

const useSetTableControlsHeight = () => {
  const dispatch = useDispatch();

  const { selectedGroupBlockId, tableControlsHeight, autoResizeDisabled } =
    useAppSelector((state: RootState) => state.annotationTableControlsReducer);

  const { countPerGroupBlockId } = useMultipleGroupBlocks();

  const setTableControlsHeightByLines = useCallback(
    (value?: string) => {
      if (autoResizeDisabled) {
        return;
      }

      const groupBlockId = value ?? selectedGroupBlockId;
      if (!groupBlockId) {
        return;
      }

      const count = countPerGroupBlockId(groupBlockId);

      if (count < 6) {
        dispatch(
          setTableControlsHeight(
            TABLE_HEADER_HEIGHT + GROUPBLOCK_TABS_HEIGHT + count * ROW_HEIGHT
          )
        );
      } else {
        dispatch(setTableControlsHeight(DEFAULT_TABLE_CONTROLS_HEIGHT));
      }
    },
    [countPerGroupBlockId, dispatch, selectedGroupBlockId, autoResizeDisabled]
  );

  const setTableControlsHeightOnAddLine = useCallback(() => {
    if (autoResizeDisabled) {
      return;
    }

    if (tableControlsHeight < DEFAULT_TABLE_CONTROLS_HEIGHT) {
      dispatch(setTableControlsHeight(DEFAULT_TABLE_CONTROLS_HEIGHT));
    }
  }, [tableControlsHeight, dispatch, autoResizeDisabled]);

  const setTableControlHeight = useCallback(
    (height: number) => {
      dispatch(setTableControlsHeight(height));
    },
    [dispatch]
  );

  return {
    setTableControlsHeightByLines,
    setTableControlsHeightOnAddLine,
    setTableControlHeight,
  };
};

export default useSetTableControlsHeight;
