import {
  DocumentSetMetrics,
  GraphLineMetrics,
  GraphPieMetrics,
  SourceColor,
} from "../interfaces/documentSetMetrics";
import {
  OTHER_SOURCE_COLOR,
  PIE_GRAPH_SHOW_MULTIPLE_PERCENTAGE,
  SOURCE_COLORS,
} from "../constants";

export const calculateTotalMetrics = (
  data: Array<GraphLineMetrics>
): Array<GraphPieMetrics> => {
  if (!data.length) {
    return [];
  }

  let totalGroup = 0;

  const groupedData = data.reduce((acc, item) => {
    const { name, value } = item;
    acc[name] = (acc[name] || 0) + value;
    totalGroup += value;
    return acc;
  }, {} as { [sourceName: string]: number });

  const grouped: Array<GraphPieMetrics> = Object.entries(groupedData).map(
    ([name, total]) => ({
      name,
      total,
      percentage: (total / totalGroup) * 100,
      color: "green",
    })
  );

  const otherSourcesPieMetric: GraphPieMetrics = {
    name: "",
    total: 0,
    otherSources: [],
  };

  // When a group's percentage falls below a certain threshold, move the group to the "otherSources" section.
  const pieMetrics: Array<GraphPieMetrics> = grouped.reduce(
    (acc: Array<GraphPieMetrics>, group: GraphPieMetrics) => {
      if (
        group.percentage &&
        group.percentage < PIE_GRAPH_SHOW_MULTIPLE_PERCENTAGE
      ) {
        otherSourcesPieMetric.otherSources!.push({
          name: group.name,
          total: group.total,
        });
        otherSourcesPieMetric.total += group.total;
      } else {
        acc.push(group);
      }
      return acc;
    },
    []
  );

  if (otherSourcesPieMetric.otherSources?.length === 1) {
    otherSourcesPieMetric.name = otherSourcesPieMetric.otherSources[0].name;
  }
  if (otherSourcesPieMetric.total > 0) {
    pieMetrics.push(otherSourcesPieMetric);
  }

  pieMetrics.sort((item1, item2) => item1.total - item2.total);

  return pieMetrics;
};

export const getSourceColorsForPieGraph = (
  metrics: Array<GraphLineMetrics>
): Array<SourceColor> => {
  if (!metrics.length) {
    return [];
  }

  const sourcesByOrder = [...new Set(metrics.map((m) => m.name))];
  return sourcesByOrder.map((source, index) => ({
    source,
    color:
      SOURCE_COLORS.length > index ? SOURCE_COLORS[index] : OTHER_SOURCE_COLOR,
  }));
};

export const calculateAllTotalMetrics = (
  data: DocumentSetMetrics,
  isBySource: boolean
): DocumentSetMetrics => {
  data.approvedMetricsTotals = {
    metrics: calculateTotalMetrics(data.approved),
    sourceColors: getSourceColorsForPieGraph(data.approved),
  };
  data.rejectedMetricsTotals = {
    metrics: calculateTotalMetrics(data.rejected),
    sourceColors: getSourceColorsForPieGraph(data.rejected),
  };

  if (isBySource) {
    data.autoApprovedMetricsTotals = {
      metrics: calculateTotalMetrics(data.autoApproved),
      sourceColors: getSourceColorsForPieGraph(data.autoApproved),
    };
    data.manualVsStpApprovedMetricsTotals = {
      metrics: calculateTotalMetrics(data.manualVsStpApproved),
      sourceColors: getSourceColorsForPieGraph(data.manualVsStpApproved),
    };
  }

  return data;
};
