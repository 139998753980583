import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback } from "react";
import {
  EntityAnnotationDto,
  EntityAnnotationBatchToBeNormalizedDto,
  EntityAnnotationToBeNormalizedDto,
} from "../../annotation/interfaces/annotation";
import {
  setIsLoadingAnnotation,
  setIsLoadingAnnotations,
  updateAnnotationNormalizationInState,
} from "../../annotation/store/annotationSlice";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { Annotation } from "../../annotator/interfaces/annotation";
import { mapToEntityAnnotationDto } from "../../annotation/utils/annotationMappers";
import {
  DocumentCategoryAnnotation,
  ICategorization,
} from "../interfaces/category";
import { ERROR_WHILE_NORMALIZING } from "../utils/validationUtils";
import selectCategorizations from "../../metadata/selectors/selectCategorizations";
import {
  useGetNormalizedAnnotationBatchMutation,
  useGetNormalizedAnnotationMutation,
} from "../store/configMapApi";

const useAnnotationNormalization = () => {
  const { t } = useTranslation("annotations");

  const dispatch = useAppDispatch();

  const metaData = useAppSelector(
    (state) => state.documentReducer.activeDocument?.metaData
  );

  const categorizations = useAppSelector((state) =>
    selectCategorizations(state)
  );

  const categoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState?.documentCategoryAnnotations
  );

  const [getNormalizedAnnotation] = useGetNormalizedAnnotationMutation();
  const [getNormalizedAnnotationBatch] =
    useGetNormalizedAnnotationBatchMutation();

  const getCategoryValue = useCallback(
    (
      entityTypeId?: string,
      selectedValue?: string,
      initCategorizations?: Array<ICategorization>,
      initCategoryAnnotations?: Array<DocumentCategoryAnnotation>
    ) => {
      let categoryAnnotationValue = selectedValue;
      const allCategorizations = initCategorizations ?? categorizations;
      const allCategoryAnnotations =
        initCategoryAnnotations ?? categoryAnnotations;

      if (!selectedValue && entityTypeId) {
        const categorization = allCategorizations?.find((c) =>
          c.entityTypes.find((et) => et.id === entityTypeId)
        );

        if (categorization) {
          const categoryAnnotation = allCategoryAnnotations.find(
            (ca) => ca.categorizationId === categorization?.id
          );
          categoryAnnotationValue = categorization?.categories.find(
            (c) => c.id === categoryAnnotation?.categoryId
          )?.value;
        }
      }

      return categoryAnnotationValue;
    },
    [categoryAnnotations, categorizations]
  );

  const getCategoryValueByAnnotation = useCallback(
    (
      entityAnnotation: EntityAnnotationDto,
      initCategorizations?: Array<ICategorization>,
      initCategoryAnnotations?: Array<DocumentCategoryAnnotation>
    ) => {
      let categoryValue = entityAnnotation.categoryValue;

      if (!categoryValue) {
        categoryValue = getCategoryValue(
          entityAnnotation.entityTypeId,
          categoryValue,
          initCategorizations,
          initCategoryAnnotations
        );

        return categoryValue;
      }
    },
    [getCategoryValue]
  );

  const normalizeEntityAnnotation = useCallback(
    async (entityAnnotation: EntityAnnotationDto): Promise<void> => {
      if (!entityAnnotation.entityNormalizations?.length) {
        return undefined;
      }

      entityAnnotation.categoryValue =
        getCategoryValueByAnnotation(entityAnnotation);

      const entityAnnotationBody: EntityAnnotationToBeNormalizedDto = {
        entityAnnotation: entityAnnotation,
        metaData: metaData,
      };

      try {
        dispatch(
          setIsLoadingAnnotation({
            id: entityAnnotation.id,
            isLoading: true,
            multipleGroupBlocks: entityAnnotation.multipleGroupBlocks,
          })
        );

        const normalizedAnnotation = await getNormalizedAnnotation(
          entityAnnotationBody
        ).unwrap();

        dispatch(
          updateAnnotationNormalizationInState({
            id: entityAnnotation.id,
            newValue: normalizedAnnotation.normalizedValue,
            method: normalizedAnnotation.normalizedMethod,
            options: normalizedAnnotation.options,
            multipleGroupBlocks: entityAnnotation.multipleGroupBlocks,
            isByUser: true,
          })
        );
      } catch (error: any) {
        notification.error({
          message: t("normalizationError"),
        });

        dispatch(
          updateAnnotationNormalizationInState({
            id: entityAnnotation.id,
            newValue: ERROR_WHILE_NORMALIZING,
            method: undefined,
            multipleGroupBlocks: entityAnnotation.multipleGroupBlocks,
            isByUser: true,
          })
        );
      } finally {
        dispatch(
          setIsLoadingAnnotation({
            id: entityAnnotation.id,
            isLoading: false,
            multipleGroupBlocks: entityAnnotation.multipleGroupBlocks,
          })
        );
      }
    },
    [
      metaData,
      dispatch,
      t,
      getCategoryValueByAnnotation,
      getNormalizedAnnotation,
    ]
  );

  const normalizeEntityAnnotationsInBulk = useCallback(
    async (
      entityAnnotations: Array<EntityAnnotationDto>,
      searchCategoryValue: boolean = false,
      initCategorizations?: Array<ICategorization>,
      initCategoryAnnotations?: Array<DocumentCategoryAnnotation>
    ) => {
      if (!entityAnnotations.length) {
        return undefined;
      }

      if (searchCategoryValue) {
        entityAnnotations = [...entityAnnotations].map((entityAnnotation) => {
          return {
            ...entityAnnotation,
            categoryValue: getCategoryValueByAnnotation(
              entityAnnotation,
              initCategorizations,
              initCategoryAnnotations
            ),
          };
        });
      }

      const entityAnnotationsBody: EntityAnnotationBatchToBeNormalizedDto = {
        entityAnnotations: entityAnnotations,
        metaData: metaData,
      };

      const toUpdateIds = entityAnnotations.map((ea) => ea.id);

      try {
        dispatch(
          setIsLoadingAnnotations({
            ids: toUpdateIds,
            isLoading: true,
          })
        );

        const normalizedAnnotations = await getNormalizedAnnotationBatch(
          entityAnnotationsBody
        ).unwrap();

        normalizedAnnotations.forEach((normalizedAnnotation) => {
          if (!normalizedAnnotation.id) {
            return;
          }

          const multipleGroupBlocks = entityAnnotations.find(
            (a) => (a.id = normalizedAnnotation.id!)
          )!.multipleGroupBlocks;

          dispatch(
            updateAnnotationNormalizationInState({
              id: normalizedAnnotation.id,
              newValue: normalizedAnnotation.normalizedValue,
              method: normalizedAnnotation.normalizedMethod,
              options: normalizedAnnotation.options,
              multipleGroupBlocks: multipleGroupBlocks,
              isByUser: true,
            })
          );
        });
      } catch (error: any) {
        notification.error({
          message: t("normalizationError"),
        });

        entityAnnotations.forEach((entityAnnotation) => {
          dispatch(
            updateAnnotationNormalizationInState({
              id: entityAnnotation.id,
              newValue: ERROR_WHILE_NORMALIZING,
              method: undefined,
              multipleGroupBlocks: entityAnnotation.multipleGroupBlocks,
              isByUser: true,
            })
          );
        });
      } finally {
        dispatch(
          setIsLoadingAnnotations({
            ids: toUpdateIds,
            isLoading: false,
          })
        );
      }
    },
    [
      metaData,
      dispatch,
      t,
      getCategoryValueByAnnotation,
      getNormalizedAnnotationBatch,
    ]
  );

  const normalizeIfAnnotationNeedsToBeNormalized = useCallback(
    (annotations: Array<Annotation>) => {
      annotations.forEach((annotation) => {
        if (annotation.triggerNormalization) {
          const entityAnnotationDto = mapToEntityAnnotationDto(
            annotation.id,
            annotation.values.join(" "),
            annotation.entity.entityNormalizations ?? [],
            annotation.entity.id,
            annotation.multipleGroupBlocks
          );
          normalizeEntityAnnotation(entityAnnotationDto);
        }
      });
    },
    [normalizeEntityAnnotation]
  );

  return {
    normalizeEntityAnnotation,
    normalizeEntityAnnotationsInBulk,
    normalizeIfAnnotationNeedsToBeNormalized,
  };
};

export default useAnnotationNormalization;
