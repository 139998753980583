import React, { FC } from "react";
import { Button } from "antd";
import useMetricsModeSelector from "../../../hooks/useMetricsModeSelector";
import { useTranslation } from "react-i18next";

const MetricsModeSelector: FC = () => {
  const { isBySource, handleChange, loading } = useMetricsModeSelector();

  const { t } = useTranslation("analytics");

  return (
    <div className="header-status__container">
      <Button
        className={`header__status-button ${isBySource && "active"}`}
        icon={<i className="bi bi-building" />}
        onClick={() => handleChange(true)}
        disabled={loading}
      >
        {t("metricsMode.sources")}
      </Button>
      <Button
        className={`header__status-button ${!isBySource && "active"}`}
        icon={<i className="bi bi-people" />}
        onClick={() => handleChange(false)}
        disabled={loading}
      >
        {t("metricsMode.users")}
      </Button>
    </div>
  );
};

export default MetricsModeSelector;
