import React, { FC } from "react";
import "./MetricGraphs.scss";
import { DocumentSetMetrics } from "../../interfaces/documentSetMetrics";
import LineGraph from "./lineGraph/LineGraph";
import { useTranslation } from "react-i18next";
import StackedColumnLineGraph from "./stackedColumnLineGraph/StackedColumnLineGraph";
import { Alert } from "antd";
import InteractiveLinePieGraph from "./InteractiveLinePieGraph";
import AverageNumber from "./number/AverageNumber";

type Props = {
  metrics: DocumentSetMetrics | undefined;
  loading: boolean;
  isBySource: boolean;
  showNoMetricsAlert: boolean;
};

const MetricGraphs: FC<Props> = ({
  metrics,
  loading,
  isBySource,
  showNoMetricsAlert,
}) => {
  const { t } = useTranslation("analytics");

  return (
    <div className="metric-graphs__container">
      {showNoMetricsAlert && !loading ? (
        <Alert
          message={t("noMetricsFound")}
          type="info"
          showIcon
          className="graph-alert"
        />
      ) : null}

      <InteractiveLinePieGraph
        title={t("metricGraphs.documentsProcessed")}
        lineGraphMetrics={metrics?.approved}
        pieGraphMetrics={metrics?.approvedMetricsTotals?.metrics}
        sourceColors={metrics?.approvedMetricsTotals?.sourceColors}
        loading={loading}
      />

      {isBySource && (
        <>
          <InteractiveLinePieGraph
            title={t("metricGraphs.autoDocumentsProcessed")}
            lineGraphMetrics={metrics?.autoApproved}
            pieGraphMetrics={metrics?.autoApprovedMetricsTotals?.metrics}
            sourceColors={metrics?.autoApprovedMetricsTotals?.sourceColors}
            loading={loading}
          />
          <InteractiveLinePieGraph
            title={t("metricGraphs.manualVsStpDocumentsProcessed")}
            lineGraphMetrics={metrics?.manualVsStpApproved}
            pieGraphMetrics={metrics?.manualVsStpApprovedMetricsTotals?.metrics}
            sourceColors={
              metrics?.manualVsStpApprovedMetricsTotals?.sourceColors
            }
            loading={loading}
          />
        </>
      )}
      <InteractiveLinePieGraph
        title={"Rejected"}
        lineGraphMetrics={metrics?.rejected}
        pieGraphMetrics={metrics?.rejectedMetricsTotals?.metrics}
        sourceColors={metrics?.rejectedMetricsTotals?.sourceColors}
        loading={loading}
      />
      <LineGraph
        title={t("metricGraphs.averageProcessingTime")}
        metrics={metrics?.processing}
        loading={loading}
        isTimeGraph={true}
      />
      {isBySource && (
        <>
          <div className="metric-graphs-row">
            <div className="line-graph">
              <LineGraph
                title={t("metricGraphs.firstTimeRight")}
                metrics={metrics?.firstTimeRight}
                loading={loading}
                tooltipSuffix="%"
              />
            </div>
            <div className="pie-graph">
              <AverageNumber
                title={t("metricGraphs.averagePeriod")}
                metrics={metrics?.firstTimeRight}
                valueSuffix="%"
              />
            </div>
          </div>
          <StackedColumnLineGraph
            title={t("metricGraphs.averageCorrectionPerDocument")}
            metrics={metrics?.corrections}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default MetricGraphs;
