export const LINE_GRAPH_HEIGHT = 250;
export const PIE_GRAPH_HEIGHT = 270;

export const METRIC_FILTER_ITEM_DELIMITER = "=";

export enum MetricsDatePeriod {
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  ONE_YEAR,
  CUSTOM,
}

export const PIE_GRAPH_SHOW_MULTIPLE_PERCENTAGE = 5;

export const OTHER_SOURCE_COLOR = "gray";

export const SOURCE_COLORS = [
  "#3498db",
  "#e74c3c",
  "#2ecc71",
  "#f39c12",
  "#9b59b6",
  "#1abc9c",
  "#e67e22",
  "#27ae60",
  "#d35400",
  "#34495e",
  "#ff00ff",
  "#1e90ff",
  "#ffff54",
  "#dda0dd",
  "#ff1493",
  "#98fb98",
  "#87cefa",
  "#ffe4b5",
  "#2f4f4f",
  "#006400",
  "#00008b",
  "#b03060",
  "#ff4500",
  "#ffff00",
  "#deb887",
  "#00ff00",
  "#00ffff",
  "#ff00ff",
  "#6495ed",
  "#2e8b57",
  "#ff8c00",
  "#00ff88",
  "#0000ff",
  "#ff1493",
  "#008080",
];
