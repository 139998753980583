import { useAppDispatch, useAppSelector } from "../../app";
import { Key, useCallback, useState } from "react";
import { setStpEntities, setStpSuppliers } from "../store/organizationSlice";
import {
  useLazyRemoveStpEntitiesQuery,
  useLazyRemoveStpSuppliersQuery,
} from "../store/organizationApi";

const useStpSettings = () => {
  const dispatch = useAppDispatch();

  const { stpEntities } = useAppSelector(
    (state) => state.organizationReducer.settings.stp
  );
  const { documentTypeId } = useAppSelector(
    (state) => state.organizationReducer.settings
  );

  const [selectedRows, setSelectedRows] = useState<Array<Key>>([]);

  const { suppliers } = useAppSelector(
    (state) => state.organizationReducer.settings.stp
  );

  const [removeStpSuppliers] = useLazyRemoveStpSuppliersQuery();
  const [removeStpEntities] = useLazyRemoveStpEntitiesQuery();

  const removeSuppliers = useCallback(
    async (ids: Array<string>) => {
      try {
        await removeStpSuppliers({ ids }).unwrap();

        const updatedSuppliers = [...suppliers].filter(
          (s) => !ids.includes(s.id)
        );

        dispatch(setStpSuppliers({ suppliers: updatedSuppliers }));
        setSelectedRows([]);
      } catch (e) {
        console.error(e);
      }
    },
    [suppliers, dispatch, removeStpSuppliers]
  );

  const removeEntities = useCallback(
    async (ids: Array<string>) => {
      try {
        await removeStpEntities({ ids }).unwrap();

        const updatedEntities = [...stpEntities].filter(
          (s) => !ids.includes(s.id!)
        );

        dispatch(setStpEntities({ entities: updatedEntities }));
        setSelectedRows([]);
      } catch (e) {
        console.error(e);
      }
    },
    [stpEntities, dispatch, removeStpEntities]
  );

  return {
    removeSuppliers,
    removeEntities,
    stpEntities,
    selectedRows,
    setSelectedRows,
    documentTypeId,
  };
};

export default useStpSettings;
